<template>
  <AppHeaderRebranded
    v-if="isV2"
    :nav="nav"
    :log-in-link="logInLink"
    :sign-out-link="signOutLink"
    :account-number="accountNumber"
    :user="user"
    :user-status="userStatus"
    :logo-image="logoImage"
    :link-logo-to-main-website="linkLogoToMainWebsite"
    :hide-menu="hideMenu"
    :hide-nav-menu="hideNavMenu"
    :tracking-click-group="trackingClickGroup"
    :is-prospect-switch-journey-enabled="isProspectSwitchJourneyEnabled"
    :is-promote-renewal-enabled="isPromoteRenewalEnabled"
    :deployment-url="deploymentUrl"
    :move-out-record-fetched="moveOutRecordFetched"
    :is-w-h-d-region-eligible="isWHDRegionEligible"
    data-testid="app-header-rebranded"
    @logout="$emit('logout')"
  ></AppHeaderRebranded>
  <AppHeaderOld
    v-else
    :nav="nav"
    :log-in-link="logInLink"
    :sign-out-link="signOutLink"
    :account-number="accountNumber"
    :user="user"
    :user-status="userStatus"
    :logo-image="logoImage"
    :link-logo-to-main-website="linkLogoToMainWebsite"
    :hide-menu="hideMenu"
    :hide-nav-menu="hideNavMenu"
    :tracking-click-group="trackingClickGroup"
    :is-prospect-switch-journey-enabled="isProspectSwitchJourneyEnabled"
    :is-promote-renewal-enabled="isPromoteRenewalEnabled"
    :deployment-url="deploymentUrl"
    :move-out-record-fetched="moveOutRecordFetched"
    data-testid="app-header-old"
    @logout="$emit('logout')"
  ></AppHeaderOld>
</template>

<script>
import AppHeaderRebranded from "./AppHeaderRebranded.vue"
import AppHeaderOld from "./AppHeaderOld.vue"

export default {
  components: {
    AppHeaderOld,
    AppHeaderRebranded
  },
  emits: ["logout"],
  props: {
    nav: {
      type: Array,
      default: () => []
    },
    logInLink: {
      type: Object,
      default: () => {}
    },
    signOutLink: {
      type: Object,
      default: () => {}
    },
    accountNumber: {
      type: String,
      default: ""
    },
    user: {
      type: Object,
      default: () => ({})
    },
    userStatus: {
      type: Object,
      default: () => {}
    },
    logoImage: {
      type: Object,
      default: () => {}
    },
    linkLogoToMainWebsite: {
      type: Boolean,
      default: true
    },
    hideMenu: {
      type: Boolean,
      default: false
    },
    hideNavMenu: {
      type: Boolean,
      default: false
    },
    trackingClickGroup: {
      type: [Array, Object],
      default: null
    },
    isProspectSwitchJourneyEnabled: {
      type: Boolean,
      default: false
    },
    isPromoteRenewalEnabled: {
      type: Boolean,
      default: true
    },
    deploymentUrl: {
      type: String,
      default: null
    },
    moveOutRecordFetched: {
      type: Boolean,
      default: false
    },
    isWHDRegionEligible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isV2() {
      return process.env.VUE_APP_NEW_BRANDING === "true"
    }
  }
}
</script>
